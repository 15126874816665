import React, { useState, useEffect } from 'react';
import { PROJECTS } from './MyProjects';
import { Project } from './Project';
import ProjectCard from './ProjectCard';
import './ProjectsPage.css';

function ProjPrev() {
    const isAIML = (value: Project) => value.reason === "AI/ML";
    const isWebAPI = (value: Project) => value.reason === "Web/API";

    const [currentProjects, setCurrentProjects] = useState(PROJECTS);
    const changeType = (filter: (value: Project) => boolean) => {
        const filteredProjects = PROJECTS.filter(filter);
        const sortedProjects = filteredProjects.sort((a, b) => a.ranking! - b.ranking!);
        setCurrentProjects(sortedProjects);
    };

    const [currentProject, setCurrentProject] = useState("AI/ML");
    const changeCourse = (project: string) => {
        setCurrentProject(project);
        if (project === "AI/ML") {
            changeType(isAIML)
        } else {
            changeType(isWebAPI)
        }
    };

    useEffect(() => {
        changeType(isAIML);
    }, []);

    return (
        <>
            <div className="header"><h1>Projects</h1></div>
            <div id="projType">
                {currentProject === "AI/ML" ?
                    <><div onClick={() => changeCourse("AI/ML")} className="projTypeItem selected">AI/ML</div><div onClick={() => changeCourse("Web/API")} className="projTypeItem">Web/API</div></>
                :
                    <><div onClick={() => changeCourse("AI/ML")} className="projTypeItem">AI/ML</div><div onClick={() => changeCourse("Web/API")} className="projTypeItem selected">Web/API</div></>
                }
            </div>
            <div id="showProjects">
                {currentProjects.map((item, i) => (
                    <ProjectCard key={i} project={item}></ProjectCard>
                ))} 
            </div>
        </>
    );
}

export default ProjPrev;